<template>
  <div>
    <TopNav />
    <v-container
      v-touch="{
        right: () => toHome(),
      }"
    >
      <div class="my-9">
        <div class="d-flex align-center justify-space-between my-6 my-sm-9">
          <div class="text-h3 font-weight-black" data-aos="fade-in">
            Hospital
          </div>
          <TextLogo :width="150" accent="#d22c52" color="#21118D" />
        </div>

        <div class="text-body-1 my-6" data-aos="fade-in" data-aos-delay="100">
          We are searching for some of the world's finest hospitals to join and
          preview our unique and exclusive platform.
        </div>

        <div class="text-body-1 my-6" data-aos="fade-in" data-aos-delay="100">
          We recognise that healthcare professionals work in an already
          high-stress environment. So having to rely on unreliable, unprotected
          data, paired with a patient's insufficient medical history,
          unnecessarily elevates the difficulty of providing the best care
          possible.
        </div>

        <div class="text-body-1 my-6" data-aos="fade-in" data-aos-delay="100">
          PatRec will provide a uniform experience and service for patients and
          medical professionals across the world, protecting and transmitting
          patient information with military-grade encryption.
        </div>

        <div class="mt-9">
          <v-btn
            large
            elevation="0"
            data-aos="zoom-in"
            rounded
            href="https://calendly.com/patrec-daniel/patrec-demo"
            target="_blank"
            :block="$vuetify.breakpoint.xsOnly"
            class="mr-sm-2 mb-1 mb-sm-0"
            color="#d22c52"
            dark
          >
            <v-icon class="mr-1">mdi-calendar-clock</v-icon>
            Book a demo
          </v-btn>
          <v-btn
            to="/contact"
            data-aos="zoom-in"
            data-aos-delay="100"
            large
            text
            color="#d22c52"
            elevation="0"
            rounded
            :block="$vuetify.breakpoint.xsOnly"
          >
            <v-icon class="mr-1">mdi-email</v-icon>
            Get in touch
          </v-btn>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import TextLogo from "@/components/shared/TextLogo";
import TopNav from "@/components/shared/TopNav";

export default {
  components: { TextLogo, TopNav },
  metaInfo: () => ({
    title: "Hospital - PatRec - Connecting Healthcare - Holden Knight Group",
  }),
  methods: {
    toHome() {
      this.$router.push({
        name: "Home",
      });
    },
  },
};
</script>

<style scoped>
.text-h3 {
  color: #d22c52;
}
</style>
